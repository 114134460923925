<template>
  <v-form class="formulario" ref="form" v-model="valid" lazy-validation>
    <div class="inputs">
      <div class="bloco-1-inputs">
        <div class="titular-cartao-form">
          <span>Titular do cartão</span>
          <v-text-field
            v-model="user.cardHolderName"
            placeholder="Jailson da Silva Lima"
            :rules="[(v) => !!v || 'Por favor, insira o seu nome completo']"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
          />
        </div>

        <div class="num-cartao-form">
          <span> Número do cartão </span>
          <v-text-field
            v-model="user.cardNumber"
            placeholder="#### #### #### ####"
            v-mask="['####-####-####-####', '####-####-####-####']"
            :rules="[(v) => !!v || 'Por favor, insira o número do cartão']"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
          />
        </div>
      </div>

      <div class="bloco-2-inputs">
        <div class="validade-form">
          <span> Data de validade </span>

          <div class="content-validade">
            <div>
              <v-select
                v-model="user.dataValidade.mes"
                :items="meses"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                placeholder="Mês"
                color="secondary"
                required
                outlined
                rounded
                dense
                class="select"
              ></v-select>
            </div>

            <div>
              <v-select
                v-model="user.dataValidade.ano"
                :items="anos"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                placeholder="Ano"
                color="secondary"
                required
                outlined
                rounded
                dense
                class="select"
              ></v-select>
            </div>
          </div>
        </div>

        <div class="content-cvv-parcela">
          <div class="cvv-form">
            <span>CVV</span>
            <v-text-field
              v-model="user.cardSecuretCode"
              placeholder="000"
              :rules="[(v) => !!v || 'Por favor, insira o cvv do cartão']"
              v-mask="['###', '###']"
              color="secondary"
              required
              outlined
              dense
              rounded
              class="input"
            />
          </div>
          <div class="parcelas-form">
            <span>Número de parcelas</span>
            <v-text-field
              v-model="user.parcels"
              placeholder="1x de tal"
              :rules="[(v) => !!v || 'Por favor, insira o número de parcelas']"
              color="secondary"
              required
              outlined
              dense
              rounded
              class="input"
            />
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      user: {
        cardNumber: "",
        cardHolderName: "",
        dataValidade: {
          ano: "",
          mes: "",
        },
        cardValidate: "",
        cardSecuretCode: "",
        parcels: 1,
      },
      meses: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      anos: ["2020", "2021"],
    };
  },
  props: {
    nextStepButtonClicked: { type: Boolean, default: false },
  },
  watch: {
    user: {
      handler(nv, ov) {
        if (nv) {
          this.$emit("changedValueCard", nv);
        } else {
          this.$emit("changedValueCard", ov);
        }
      },
      deep: true,
    },
    nextStepButtonClicked(nv, ov) {
      if (nv) {
        if (this.validate()) {
          this.enviarFormulario();
        }
      }
    },
  },
  methods: {
    enviarFormulario() {
      this.user.cardValidate = `${this.user.dataValidade.ano}/${this.user.dataValidade.mes}`;
      this.$emit("pagamento", { ...this.user, ok: true });
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style  scoped>
/* FORMULARIO */
.formulario {
  max-width: 450px;
}
.titular-cartao-form {
  width: 100%;
}
.content-validade {
  display: flex;
  flex-wrap: wrap;
}
.content-validade > div {
  flex: 1 1 200px;
}
.content-cvv-parcela {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.content-cvv-parcela > div {
  flex: 1 1 200px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.bloco-1-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.bloco-2-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.bloco-2-inputs .validade-form {
}
.content-validade {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

/* FIM DO FORMULARIO */
</style>