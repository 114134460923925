var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pagamento" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("BackButton", {
          staticClass: "button",
          on: { buttonClicked: _vm.buttonCLicked },
        }),
        _c("span", [_vm._v("Pagamento")]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-pagamento" },
      [
        _c("Cartao", { attrs: { cardValue: _vm.valorParaCartao } }),
        _c("FormularioCartao", {
          attrs: { nextStepButtonClicked: _vm.nextStepButtonClicked },
          on: { changedValueCard: _vm.valorCartao, pagamento: _vm.pagamento },
        }),
      ],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "msg-obrigatorio" }, [
      _c("span", [_vm._v(" *Todos os campos são obrigatórios ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }