<template>
  <div class="endereco">
    <div class="title">
      <BackButton class="button" @buttonClicked="buttonCLicked" />
      <span>Endereço</span>
    </div>

    <v-form class="formulario" ref="form" v-model="valid" lazy-validation>
      <div class="inputs">
        <v-row>
          <v-col cols="12" sm="6">
            <div class="cep">
              <span> CEP </span>
              <v-text-field
                v-model="endereco.postalCode"
                placeholder="Digite o CEP para autopreenchimento"
                :rules="[(v) => !!v || 'Por favor, insira seu CEP']"
                color="secondary"
                required
                outlined
                rounded
                class="input"
                @input="searchLocation"
              />
              <span class="btn-cep-correio" @click="goToCorreios"
                ><span>Não sei meu CEP</span></span
              >
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex align-center justify-center">
            <!-- <v-text-field
              v-model="endereco.cepManual"
              color="secondary"
              class="input"
            /> -->
            <div class="texto-manual" @click="editarCampos">
              <small><span> Digitar endereço manualmente</span> </small>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <div class="rua">
              <span> Endereço</span>
              <v-text-field
                v-model="endereco.publicPlace"
                placeholder="Digite a rua, avenida, e etc."
                :rules="[(v) => !!v || 'Por favor, insira seu endereço']"
                color="secondary"
                required
                outlined
                rounded
                :disabled="disabled"
                class="input"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="numero">
              <span> Número </span>
              <v-text-field
                v-model="endereco.addressNumber"
                placeholder="Digite o número"
                :rules="[(v) => !!v || 'Por favor, digite o número']"
                color="secondary"
                required
                outlined
                rounded
                class="input"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="complemento">
              <span> Complemento </span>
              <v-text-field
                v-model="endereco.addressComplement"
                placeholder="Digite o complemento"
                :rules="[(v) => !!v || 'Por favor, insira algum complemento']"
                color="secondary"
                required
                outlined
                rounded
                class="input"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div class="cidade">
              <span> Cidade </span>
              <v-text-field
                v-model="endereco.city"
                placeholder="Digite a sua cidade"
                :rules="[(v) => !!v || 'Por favor, insira sua cidade']"
                color="secondary"
                required
                outlined
                rounded
                :disabled="disabled"
                class="input"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div class="cep">
              <span> Bairro </span>
              <v-text-field
                v-model="endereco.district"
                placeholder="Digite o seu bairro"
                :rules="[(v) => !!v || 'Por favor, insira seu bairro']"
                color="secondary"
                required
                outlined
                :disabled="disabled"
                rounded
                class="input"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div class="cep">
              <span> Estado </span>
              <v-select
                v-model="endereco.state"
                :items="items"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                placeholder="Selecione"
                color="secondary"
                required
                outlined
                :disabled="disabled"
                rounded
                class="select"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <div class="msg-obrigatorio">
      <span> *Todos os campos são obrigatórios </span>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../../../services/ApiService";
import BackButton from "../shared/backBtn.vue";
export default {
  data() {
    return {
      valid: true,
      apiService: new ApiService(),
      disabled: true,
      endereco: {
        postalCode: "",
        cepManual: "",
        publicPlace: "",
        addressNumber: "",
        addressComplement: "",
        city: "",
        district: "",
        state: "",
      },
      confirmEmail: "",
      items: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
        "DF",
      ],
    };
  },
  components: {
    BackButton,
  },
  props: {
    nextStepButtonClicked: { type: Boolean, default: false },
  },
  methods: {
    buttonCLicked() {
      this.$emit("back");
    },
    editarCampos(){
      this.disabled == true ? this.disabled = false : this.disabled = true
    },
    async searchLocation() {
      let formated = this.endereco.postalCode.replaceAll("-", "").trim();

      const options = {
        method: "GET",
        mode: "cors",
        cache: "default",
      };

      if (formated.length == 8) {
        try {
          let req = await fetch(
            `https://viacep.com.br/ws/${formated}/json/`,
            options
          );
          let conteudo = await req.json();

          this.endereco.district = conteudo.bairro;
          this.endereco.publicPlace = conteudo.logradouro;
          this.endereco.state = conteudo.uf;
          this.endereco.addressComplement = conteudo.complemento;
          this.endereco.city = conteudo.localidade;
        } catch (error) {
          this.$toast.error(
            "Ops, erro ao realizar a pesquisa com o cep informado"
          );
        }
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
     enviarFormulario() {
      this.$emit("endereco", { ...this.endereco, ok: true });
    },
    goToCorreios() {
      window.open("https://www.correios.com.br/", "_blank");
    },
  },
  watch: {
    nextStepButtonClicked(nv, ov) {
      if (nv) {
        if (this.validate()) {
          this.enviarFormulario();
        }
      }
    },
  },
};
</script>

<style scoped>
.endereco {
}
.endereco .title {
  margin: 1.5rem 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.endereco .button {
  margin: 0 0.4rem 0 0;
}
.cep {
  position: relative;
}
.btn-cep-correio {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  cursor: pointer;
}
.btn-cep-correio span {
  color: var(--principal-azul) !important;
  font-weight: 500;
}

.formulario {
}
.formulario > div span {
  color: #9c9c9c;
  display: block;
  margin: 0.2rem;
}
.content-documento {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  max-width: 50%;
}
.celular {
  transition: 0.2s ease-in-out;
  max-width: 25%;
}

.texto-manual {
  margin: 0 0 0.7rem 0.7rem;
  cursor: pointer;
}
.texto-manual small {
  font-size: 0.8em;
}
.texto-manual small span {
  color: #8ab6f0;
}

@media screen and (max-width: 1150px) {
  .celular {
    max-width: 50%;
  }
}
.msg-obrigatorio {
  position: absolute;
  top: 15px;
  right: 15px;
}
.msg-obrigatorio span {
  color: red;
  font-size: 0.85em;
}
@media screen and (max-width: 700px) {
  .msg-obrigatorio span {
    font-size: 0.55em;
  }
}
</style>