var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bloco-descricao-checkout" }, [
    _c(
      "div",
      {
        staticClass: "descricao",
        class: { active: _vm.step >= 1, exact: _vm.step == 1 },
      },
      [_c("span", [_vm._v("Dados pessoais")])]
    ),
    _vm.step < 1 ? _c("span", { staticClass: "border hide-1" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "descricao",
        class: { active: _vm.step >= 2, exact: _vm.step == 2 },
      },
      [_c("span", [_vm._v("Endereço")])]
    ),
    _vm.step < 2 ? _c("span", { staticClass: "border hide-2" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "descricao",
        class: { active: _vm.step >= 3, exact: _vm.step == 3 },
      },
      [_c("span", [_vm._v("Pagamento")])]
    ),
    _vm.step < 3 ? _c("span", { staticClass: "border hide-3" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "descricao",
        class: { active: _vm.step >= 4, exact: _vm.step == 4 },
      },
      [_c("span", [_vm._v("Obrigado!")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }