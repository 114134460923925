<template>
  <div class="cartao-component" v-if="!objetoUndefined(cardValue)">
    <div class="content" >
      <div class="cartao-credito">
        <div class="conteudo-cartao">
          <div class="num-cartao">
            <div class="title">
              <span> Número do cartão de crédito </span>
            </div>
            <div class="numero shadow">
              <span> {{ cardValue.cardNumber }} </span>
            </div>
          </div>

          <div class="titular-cartao">
            <div class="title">
              <span> Títular </span>
            </div>
            <div class="nome">
              <span> {{ cardValue.cardHolderName }} </span>
            </div>
          </div>

          <div class="validade-cartao-e-cvv">
            <div class="validade">
              <div class="title">
                <span> Validade </span>
              </div>
              <div class="nome">
                <span>
                  {{ cardValue.dataValidade.ano }}/{{
                    cardValue.dataValidade.mes
                  }}</span
                >
              </div>
            </div>
            <div class="cvv">
              <div class="title">
                <span> CVV </span>
                <div class="num-cvv">
                  <span>{{ cardValue.cardValidate }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardValue: { type: Object, default: () => {} },
  },
  data() {
    return {};
  },
  methods: {
    objetoUndefined(value) {
      return Object.keys(value).length === 0;
    },
  },
};
</script>

<style  scoped>
.cartao-component {
  min-height: 48vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cartao-credito {
  width: 450px;
  height: 250px;
  background: #1f8fff;
  border-radius: 10px;
  padding: 1.5rem;
  color: white;
  -webkit-box-shadow: -1px 5px 11px -8px #000000;
  box-shadow: -1px 5px 11px -8px #000000;
  margin-bottom: 3rem;
}
.conteudo-cartao > div {
  margin: 0.4rem 0 0 0;
}
.num-cartao span {
  display: block;
}

.title {
  font-weight: 300;
  font-size: 0.7em !important;
  color: rgb(235, 231, 231);
}
.num-cartao .numero {
}
.shadow {
}
.num-cartao .numero span {
  padding: 0.2rem 0.5rem;
  width: 50%;
  font-weight: 300;
  background: #3998ff;
  transition: 0.2s ease-in-out;
}
.titular-cartao .nome span {
  padding: 0.2rem 0.5rem;
  /* width: 70%; */
  font-weight: 300;
  background: #3998ff;
  transition: 0.2s ease-in-out;
}
.validade-cartao-e-cvv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cvv {
  text-align: center;
}
.cvv .num-cvv {
  padding: 0.2rem 0.5rem;
  /* width: 70%; */
  font-weight: 300;
  background: #3998ff;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 580px) {
  .cartao-credito {
    width: 80vw;
    height: 42vh;
  }

  .num-cartao .numero span {
    width: 60%;
  }
}
</style>