var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dados-pessoais" },
    [
      _vm._m(0),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "formulario",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            [
              _c("v-checkbox", {
                staticClass: "usar-dados",
                attrs: { label: "Usar dados pessoais" },
                on: { change: _vm.carregarDados },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "inputs" }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _c("span", [_vm._v(" Nome completo ")]),
                _c("v-text-field", {
                  staticClass: "input",
                  attrs: {
                    placeholder: "Joilson da SIlva Lima",
                    rules: [(v) => !!v || "Por favor, insira seu nome"],
                    color: "secondary",
                    required: "",
                    outlined: "",
                    rounded: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.user.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "name", $$v)
                    },
                    expression: "user.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "email" },
              [
                _c("span", [_vm._v("E-mail")]),
                _c("v-text-field", {
                  staticClass: "input",
                  attrs: {
                    placeholder: "nome@email.com",
                    rules: [(v) => !!v || "Por favor, insira seu E-mail"],
                    color: "secondary",
                    required: "",
                    outlined: "",
                    rounded: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.user.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "email", $$v)
                    },
                    expression: "user.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "email-confirm" },
              [
                _c("span", [_vm._v("Confirme seu e-mail")]),
                _c("v-text-field", {
                  staticClass: "input",
                  attrs: {
                    placeholder: "nome@email.com",
                    rules: [
                      (v) => !!v || "Por favor, confirme insira seu E-mail",
                    ],
                    color: "secondary",
                    required: "",
                    outlined: "",
                    dense: "",
                    rounded: "",
                  },
                  model: {
                    value: _vm.confirmEmail,
                    callback: function ($$v) {
                      _vm.confirmEmail = $$v
                    },
                    expression: "confirmEmail",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "documento" }, [
              _c("span", [_vm._v(" Documento")]),
              _c(
                "div",
                { staticClass: "content-documento" },
                [
                  _c("v-select", {
                    staticClass: "select",
                    attrs: {
                      items: _vm.items,
                      "item-text": "name",
                      "menu-props": { bottom: true, offsetY: true },
                      placeholder: "Selecione",
                      color: "secondary",
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.user.documentoType,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "documentoType", $$v)
                      },
                      expression: "user.documentoType",
                    },
                  }),
                  _vm.user.documentoType == "CPF"
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["###.###.###-##"],
                            expression: "['###.###.###-##']",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          placeholder: "000.000.00-00",
                          rules: [
                            (v) => !!v || "Por favor, insira seu documento",
                            _vm.rules.documentMinCpf,
                          ],
                          color: "secondary",
                          required: "",
                          outlined: "",
                          dense: "",
                          rounded: "",
                        },
                        model: {
                          value: _vm.user.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "document", $$v)
                          },
                          expression: "user.document",
                        },
                      })
                    : _vm._e(),
                  _vm.user.documentoType == "CNPJ"
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["##.###.###/####-##"],
                            expression: "['##.###.###/####-##']",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          placeholder: "00.000.000/0000-00",
                          rules: [
                            (v) => !!v || "Por favor, insira seu documento",
                            _vm.rules.documentMinCNPJ,
                          ],
                          color: "secondary",
                          required: "",
                          outlined: "",
                          dense: "",
                          rounded: "",
                        },
                        model: {
                          value: _vm.user.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "document", $$v)
                          },
                          expression: "user.document",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "celular" },
              [
                _c("span", [_vm._v(" Celular ")]),
                _c("v-text-field", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["(##)####-####", "(##)#####-####"],
                      expression: "['(##)####-####', '(##)#####-####']",
                    },
                  ],
                  staticClass: "input",
                  attrs: {
                    placeholder: "(00) 0 0000-0000",
                    rules: [
                      (v) => !!v || "Por favor, insira seu celular",
                      _vm.rules.minCelular,
                    ],
                    color: "secondary",
                    required: "",
                    outlined: "",
                    rounded: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.user.phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "phoneNumber", $$v)
                    },
                    expression: "user.phoneNumber",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(" Dados pessoais ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "msg-obrigatorio" }, [
      _c("span", [_vm._v(" *Todos os campos são obrigatórios ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }