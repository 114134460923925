<template>
  <div class="bloco-descricao-checkout">

    <div class="descricao" :class="{ active: step >= 1, exact: step == 1 }">
      <span>Dados pessoais</span>
    </div>

    <span class="border hide-1" v-if="step < 1"></span>

    <div class="descricao" :class="{ active: step >= 2, exact: step == 2 }">
      <span>Endereço</span>
    </div>

    <span class="border hide-2" v-if="step < 2"></span>

    <div class="descricao" :class="{ active: step >= 3, exact: step == 3 }">
      <span>Pagamento</span>
    </div>

    <span class="border hide-3" v-if="step < 3"></span>

    <div class="descricao" :class="{ active: step >= 4, exact: step == 4 }">
      <span>Obrigado!</span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    step: { type: Number, default: 0 },
  },
};
</script>

<style  scoped>
.bloco-descricao-checkout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
}
.bloco-descricao-checkout > div {
  flex: 1 1 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 25vh;
}
.bloco-descricao-checkout .border {
  border: 1px solid #b9b9b9;
  width: 1px;
  height: 60px;
}
.active {
  background: white;
  font-weight: 700;
  color: var(--principal-azul);
}
.exact {
  border: 2px solid var(--principal-azul);
}

@media screen and (max-width: 630px) {
  .bloco-descricao-checkout{
  }
  .bloco-descricao-checkout >div{
    height: 100px;
  }
  .hide-3{
    display: none;
  }
}
@media screen and (max-width: 490px) {
  .hide-2{
    display: none;
  }
  .hide-3{
    display: block;
  }
}
</style>