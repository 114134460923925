var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "error-component" }, [
      _c("div", { staticClass: "barra-topo" }),
      _c("div", { staticClass: "content" }, [
        _c("figure", { staticClass: "img" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/icons/shopping_cart_icon.svg"),
              alt: "carrinho de compra",
            },
          }),
        ]),
        _c("div", { staticClass: "texts" }, [
          _c("span", { staticClass: "strong" }, [
            _vm._v("Não é possível comprar"),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " Este produto não está mais acessível para compra. Ele foi pausado, desativado ou descontinuado, procure a entidade dona para saber o que houve. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "btn-fale-conosco" }, [
          _c("span", [_vm._v("Fale conosco")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }