<template>
  <div class="pagamento">
    <div class="title">
      <BackButton class="button" @buttonClicked="buttonCLicked" />
      <span>Pagamento</span>
    </div>

    <div class="content-pagamento">
      <Cartao :cardValue="valorParaCartao" />
      <FormularioCartao
        @changedValueCard="valorCartao"
        @pagamento="pagamento"
        :nextStepButtonClicked="nextStepButtonClicked"
      />
    </div>

    <div class="msg-obrigatorio">
      <span> *Todos os campos são obrigatórios </span>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../../../services/ApiService";
import BackButton from "../shared/backBtn.vue";
import Cartao from "./FormasPagamento/Cartao.vue";
import FormularioCartao from "./FormasPagamento/FormularioCartaoCredito.vue";
export default {
  data() {
    return {
      valid: true,
      apiService: new ApiService(),
      btnClicked: true,
      currentView: "Boleto",
      valorParaCartao: {},
    };
  },
  props: {
    nextStepButtonClicked: { type: Boolean, default: false },
  },
  components: {
    BackButton,
    Cartao,
    FormularioCartao,
  },
  methods: {
    buttonCLicked() {
      this.$emit("back");
    },
    valorCartao(value) {
      this.valorParaCartao = value;
    },
    enviarFormulario() {
      //console.log(this.valorParaCartao)
      //pegar o valor bia props
      // this.$emit("pagamento", { ...this.user, ok: true });
    },
    pagamento(){
      this.$emit('pagamento', {...this.valorParaCartao, ok: true})
    }
  },
};
</script>

<style scoped>
.pagamento {
  /* border: 1px solid red !important; */
}
.pagamento .title {
  margin: 1.5rem 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.pagamento .button {
  margin: 0 0.4rem 0 0;
}
.content-pagamento {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.5rem;
}
.content-pagamento > div {
  flex: 1;
}

.content-pagamento .formas-pagamento {
}
.strong {
  font-weight: bold;
}
.formas-pagamento {
  display: flex;
  align-items: center;
  justify-content: center;
}
.opcoes .title-pagmento {
  text-align: center;
  color: #b9b9b9;
}
.formas-pagamento .btn {
  border: 1px solid var(--principal-azul);
  width: 250px;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: var(--principal-azul);
}
.btn img {
  margin-right: 0.5rem;
  filter: invert(50%) sepia(10%) saturate(6559%) hue-rotate(197deg)
    brightness(112%) contrast(90%);
}
.active {
  background: var(--principal-azul);
  color: white !important;
}
.active img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(130%);
}
.msg-obrigatorio {
  position: absolute;
  top: 15px;
  right: 15px;
}
.msg-obrigatorio span {
  color: red;
  font-size: 0.85em;
}
@media screen and (max-width: 700px) {
  .msg-obrigatorio span {
    font-size: 0.55em;
  }
}
</style>