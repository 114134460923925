<template>
  <v-card class="detalhes-pedido">
    <div class="content">
      <div class="title">
        <span> Detalhes do pedido: </span>
      </div>

      <div class="elements">
        <!-- <figure class="imagem-plano"></figure> -->

        <div class="descricoes">
          <span class="title-descricao"> Plano {{planoEscolhido.title}} </span>
          <span class="descricao-produto">
            {{planoEscolhido.periodicidadePagamento}}
          </span>
          <span class="empresa"> Kristal Connect </span>

          <span class="valor"> {{planoEscolhido.valor}} </span>
          <span class="tipo-pagamento"> Pagamento à vista </span>
        </div>
      </div>
    </div>

    <v-btn :loading="loading" color="primary" class="btn-prosseguir" @click="next" v-if="step < 4">
      <span> Prosseguir </span>
    </v-btn>
    <div class="btn-prosseguir principalPagina" @click="goToLogin" v-if="step == 4">
      <span> Ir para carteira </span>
    </div>

    <div class="seguranca">
      <img src="@/assets/icons/shield_icon.svg" alt="shield" />
      <span> COMPRA 100% SEGURA </span>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    step: { type: Number, default: 0 },
    loading: {type: Boolean, default: false}
  },
  data() {
    return {
      planoEscolhido: undefined
    };
  },
  created(){
    this.planoEscolhido = this.$store.getters.$currentPlan
  },
  methods: {
    next() {
      this.$emit("next");
    },
    goToLogin(){
      sessionStorage.removeItem('preUserRegister')
      //const profileType = this.$store.getters.$profileType;
      let route = "visaoGeral";
      // if (profileType.id == 0) {
      //   route = "visaoGeral"
      // }

      this.$router.push({name: route});
    }
  },
};
</script>
<style scoped>
.detalhes-pedido {
  padding: 1rem 2.5rem;
  position: relative;
}
.content .title {
  margin: 0.8rem 0;
}
.content .title span {
  font-weight: 700;
  font-size: 0.9em;
  color: black;
}
.elements {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.elements > div,
figure {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.imagem-plano {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background: #a4a4a4;
}
.elements span {
  display: block;
}
.descricoes {
  color: black;
}
.descricoes .title-descricao {
  font-weight: 600;
  font-size: 0.85em;
}
.descricoes .descricao-produto {
  font-weight: 300;
  font-size: 0.7em;
  color: #5f6368;
}
.descricoes .empresa {
  margin: 2rem 0 0 0;
  font-weight: 600;
  font-size: 0.7em;
}
.descricoes .valor {
  font-weight: 400;
  font-size: 1.3em;
  margin: 0.2rem 0;
}
.descricoes .tipo-pagamento {
  color: #b9b9b9;
  font-weight: 700;
  font-size: 0.7em;
}
.btn-prosseguir {
  background: var(--principal-azul);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  cursor: pointer;
  margin: 1rem auto;
  border-radius: 20px;
  padding: 0.2rem;
  transition: 0.2s ease-in;
}
.btn-prosseguir:hover {
  background: var(--principal-azul-hover);
}
.principalPagina{
  width: 250px;
}
.seguranca {
  background: #7cd063;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 15px;
  width: 250px;
  border-radius: 0 0 0 0 !important;
  padding: 0.5rem;
}
.seguranca img {
  margin: 0 0.3rem;
}

@media screen and (max-width: 700px) {
  .seguranca {
    font-size: 0.5em;
    width: 140px;
  }
  .seguranca img {
    width: 10px;
  }
}
</style>