<template>
  <div class="dados-pessoais">

    <div class="title">
      <span> Dados pessoais </span>
    </div>

    <v-form class="formulario" ref="form" v-model="valid" lazy-validation>
      <div>
        <v-checkbox
          class="usar-dados"
          label="Usar dados pessoais"
          @change="carregarDados"
        ></v-checkbox>
      </div>
      <div class="inputs">
        <div class="name">
          <span> Nome completo </span>
          <v-text-field
            v-model="user.name"
            placeholder="Joilson da SIlva Lima"
            :rules="[(v) => !!v || 'Por favor, insira seu nome']"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
          />
        </div>

        <div class="email">
          <span>E-mail</span>
          <v-text-field
            v-model="user.email"
            placeholder="nome@email.com"
            :rules="[(v) => !!v || 'Por favor, insira seu E-mail']"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
          />
        </div>

        <div class="email-confirm">
          <span>Confirme seu e-mail</span>
          <v-text-field
            v-model="confirmEmail"
            placeholder="nome@email.com"
            :rules="[(v) => !!v || 'Por favor, confirme insira seu E-mail']"
            color="secondary"
            required
            outlined
            dense
            rounded
            class="input"
          />
        </div>

        <div class="documento">
          <span> Documento</span>

          <div class="content-documento">
            <v-select
              v-model="user.documentoType"
              :items="items"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione"
              color="secondary"
              required
              outlined
              rounded
              dense
              class="select"
            ></v-select>

            <v-text-field
              v-if="user.documentoType == 'CPF'"
              v-model="user.document"
              placeholder="000.000.00-00"
              v-mask="['###.###.###-##']"
              :rules="[(v) => !!v || 'Por favor, insira seu documento', rules.documentMinCpf]"
              color="secondary"
              required
              outlined
              dense
              rounded
              class="input"
            />
            <v-text-field
              v-if="user.documentoType == 'CNPJ'"
              v-model="user.document"
              v-mask="['##.###.###/####-##']"
              placeholder="00.000.000/0000-00"
              :rules="[(v) => !!v || 'Por favor, insira seu documento', rules.documentMinCNPJ]"
              color="secondary"
              required
              outlined
              dense
              rounded
              class="input"
            />
          </div>
        </div>

        <div class="celular">
          <span> Celular </span>

          <v-text-field
            v-model="user.phoneNumber"
            placeholder="(00) 0 0000-0000"
            v-mask="['(##)####-####', '(##)#####-####']"
            :rules="[(v) => !!v || 'Por favor, insira seu celular', rules.minCelular]"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
          />
        </div>
      </div>
    </v-form>

    <div class="msg-obrigatorio">
      <span> *Todos os campos são obrigatórios </span>
    </div>
  </div>
</template>

<script>
import Utils from "../../../../../Utils/ValidateCpnjCpf"
export default {
  data() {
    return {
      valid: true,
      user: {
        name: "",
        email: "",
        documentoType: "",
        document: "",
        phoneNumber: "",
      },
      userVuex: undefined,
      confirmEmail: "",
      items: ["CPF", "CNPJ"],
      rules:{
        minCelular: value => value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').length >= 11 || 'Número de telefone inválido',
        documentMinCpf: value => this.validarCpf(value) || 'CPF inválido',
        documentMinCNPJ: value => this.validarCnpj(value) || 'CNPJ inválido',
      }
    };
  },
  props: {
    nextStepButtonClicked: { type: Boolean, default: false },
  },
  methods: {
    enviarFormulario() {
      let preLoginUser = sessionStorage.getItem('user')
      let userAtualizado = JSON.parse(preLoginUser)
      sessionStorage.removeItem('user')

      if(this.$store.getters.$preUserRegister != null || this.$store.getters.$preUserRegister != undefined){
        this.user.userId = this.$store.getters.$preUserRegister.id
      }else{
        this.user.userId = this.$store.getters.$userLogged.id
      }

      this.user.planType = this.$route.params.plan // ta dando erro nesse cara???/

      userAtualizado.planType = this.$route.params.plan
      userAtualizado.phoneNumber = this.user.phoneNumber
      this.user.annualPlan = this.$route.params.annualPlan
      this.user.paymentValue = this.$store.getters.$currentPlan.value
      this.$store.dispatch('PUT_PLAN_TYPE', this.user.planType)
      sessionStorage.setItem('user', JSON.stringify(userAtualizado))

      this.$emit("dadosPessoais", { ...this.user, ok: true });
    },
    validate() {
      return this.$refs.form.validate();
    },
    validarCpf(value) {
      return Utils.validarCpf(value);
    },
    validarCnpj(value) {
      return Utils.validarCNPJ(value);
    },
     verificarTamanhoCPF(value){
      let size = value.replaceAll('.', '').replaceAll('-', '').length
       return size
    },
    verificarTamanhoCNPJ(value){
      let size = value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').length

     if(size > 11 && size < 14 || size > 14){
       return false
     }
     return true
    },
     objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
    carregarDados() {
      if(this.$store.getters.$preUserRegister != null || this.$store.getters.$preUserRegister != undefined){
        //this.user.userId = this.$store.getters.$preUserRegister;
        this.user.name = this.$store.getters.$preUserRegister.name;
        this.user.email = this.$store.getters.$preUserRegister.email;
        this.user.document = this.$store.getters.$preUserRegister.document;
        this.user.documentoType = this.user.document.length > 14 ? 'CNPJ' : 'CPF';
      }else{
        this.user.userId = this.$store.getters.$userLogged.id;
        this.user.name = this.$store.getters.$userLogged.name;
        this.user.email = this.$store.getters.$userLogged.email;
        this.user.document = this.$store.getters.$userLogged.document;
        this.user.documentoType = this.user.document.length > 14 ? 'CNPJ' : 'CPF';
      }
    }
  },
  computed:{
    hasPreUser(){
      return this.objetoEhVazio(this.$store.getters.$preUserRegister)
    },
    hasUser(){
      return this.objetoEhVazio(this.$store.getters.$userLogged)
    }
  },
  watch: {
    nextStepButtonClicked(nv, ov) {
      if (nv) {
        if (this.validate()) {
          this.enviarFormulario();
        }
      }
    },
  }
};
</script>

<style scoped>
.msg-obrigatorio {
  position: absolute;
  top: 15px;
  right: 15px;
}
.msg-obrigatorio span {
  color: red;
  font-size: 0.85em;
}
@media screen and (max-width: 700px) {
  .msg-obrigatorio span {
    font-size: 0.55em;
  }
}
.dados-pessoais {
  /* border: 1px solid red !important; */
}
.dados-pessoais .title {
  margin: 1.5rem 0;
  font-weight: 700;
}

.usar-dados {
  font-weight: normal;
  margin-top: 0;
  position: absolute;
  right: 1rem;
  top: 4.5rem;
}

.usar-dados >>> .v-label {
  font-size: 0.9rem !important;
}

.formulario {
  /* border: 1px solid red !important; */
}
.formulario > div span {
  color: #9c9c9c;
  display: block;
  margin: 0.2rem 0;
}
.content-documento {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  max-width: 50%;
}
.content-documento .select {
}
.content-documento .input {
}
.celular {
  transition: 0.2s ease-in-out;
  max-width: 25%;
}

@media screen and (max-width: 1150px) {
  .celular {
    max-width: 50%;
  }
}
</style>