var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "agradecimento" }, [
    _c("div", { staticClass: "barra-topo" }),
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", { staticClass: "card-compra" }, [
        _c("div", { staticClass: "img-card" }),
        _c("div", { staticClass: "descricoes-card" }, [
          _c("div", { staticClass: "descricoes" }, [
            _c("span", { staticClass: "principal" }, [
              _vm._v(" Plano " + _vm._s(_vm.planoEscolhido.title) + " "),
            ]),
            _c("span", { staticClass: "secundario" }, [
              _vm._v(
                " " + _vm._s(_vm.planoEscolhido.periodicidadePagamento) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "valor" }, [
            _c("span", { staticClass: "empresa" }, [
              _vm._v(" Kristal Connect "),
            ]),
            _c("span", { staticClass: "preco" }, [
              _vm._v(" " + _vm._s(_vm.planoEscolhido.valor) + " "),
            ]),
            _c("span", { staticClass: "tipo-pagamento" }, [
              _vm._v(" Pagamento à vista "),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "texts" }, [
      _c("span", { staticClass: "strong" }, [_vm._v("Obrigado!")]),
      _c("span", { staticClass: "recebido" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/check-circle-solid_icon.svg"),
            alt: "check",
          },
        }),
        _vm._v(" Recebemos seu pedido "),
      ]),
      _c("span", { staticClass: "descricao" }, [
        _vm._v(
          " Você receberá um e-mail confirmando que seu pedido foi aceito e dizendo os próximos passos. Estamos processando seu pedido e assim que confirmarmos seu pagamento lhe enviaremos um novo e-mail! "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }