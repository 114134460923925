var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("DescricaoCheckout", { attrs: { step: _vm.step } }),
        _c(
          "Container",
          { staticClass: "container-stepper" },
          _vm._l(4, function (item) {
            return _c("div", { key: item, staticClass: "stepper-count" }, [
              _vm.step == item
                ? _c(
                    "div",
                    [
                      _c(_vm.passos[item - 1], {
                        tag: "component",
                        attrs: {
                          nextStepButtonClicked: _vm.nextStepButtonClicked,
                        },
                        on: {
                          back: _vm.backStep,
                          dadosPessoais: _vm.dadosPessoais,
                          endereco: _vm.endereco,
                          pagamento: _vm.pagamento,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
        _c("DetalhesPedido", {
          attrs: { loading: _vm.loading, step: _vm.step },
          on: { next: _vm.nextStep },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }