<template>
  <div class="agradecimento">
    <div class="barra-topo"></div>
    <div class="content">
      <div class="texts">
        <span class="strong">Obrigado!</span>

        <span class="recebido">
          <img src="@/assets/icons/check-circle-solid_icon.svg" alt="check" />
          Recebemos seu pedido
        </span>

        <span class="descricao">
          Você receberá um e-mail confirmando que seu pedido foi aceito e
          dizendo os próximos passos. Estamos processando seu pedido e assim que
          confirmarmos seu pagamento lhe enviaremos um novo e-mail!
        </span>
      </div>

      <div class="card-compra">
        <div class="img-card"></div>
        <div class="descricoes-card">
          <div class="descricoes">
            <span class="principal"> Plano {{planoEscolhido.title}} </span>
            <span class="secundario">
              {{planoEscolhido.periodicidadePagamento}}
            </span>
          </div>

          <div class="valor">
            <span class="empresa"> Kristal Connect </span>
            <span class="preco"> {{planoEscolhido.valor}} </span>
            <span class="tipo-pagamento"> Pagamento à vista </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      planoEscolhido: undefined
    }
  },
  created(){
    this.planoEscolhido = this.$store.getters.$currentPlan
  }
};
</script>

<style scoped>
.agradecimento {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.barra-topo {
  border: 10px solid var(--principal-azul);
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.content > div {
  margin: 1.5rem 0;
}
.content .texts span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
}
.recebido {
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
}
.recebido img {
  width: 30px;
  margin-right: 0.3rem;
}
.descricao {
  font-size: 0.85em;
  color: #707070;
  font-weight: 300;
  max-width: 80%;
  margin: 0 auto !important;
}

.content .card-compra {
  max-width: 60%;
  margin: 0 auto;
  background: #dbdbdb;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.card-compra .img-card {
  flex: 1 0 200px;
  height: 25vh;
  background: #a4a4a4;
  border-radius: 10px;
}
.card-compra .descricoes-card {
  flex: 2 0 200px;
}
.descricoes-card .descricoes {
  text-align: left;
}
.descricoes-card .descricoes span {
  display: block;
  margin: 0.5rem 0;
}
.descricoes .principal {
  font-weight: 600;
}
.descricoes .secundario {
  font-weight: 300;
  font-size: 0.8em;
}
.descricoes-card .valor {
  text-align: left;
}
.descricoes-card .valor span {
  display: block;
  margin: 0.3rem;
}
.valor .empresa {
  font-weight: 600;
  font-size: 0.9em;
}
.valor .preco {
  font-size: 1.6em;
}
.valor .tipo-pagamento {
  font-weight: 500;
  color: #b9b9b9;
  font-size: 0.9em;
}
.strong {
  font-weight: 700;
  color: var(--principal-azul);
  font-size: 1.5em;
}

@media screen and (max-width: 760px) {
  .card-compra {
    max-width: 90% !important;
  }
}
</style>