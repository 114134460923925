<template>
  <div class="checkout">
    <div class="content">
   <DescricaoCheckout :step="step"/>

      <Container class="container-stepper">
        <div class="stepper-count" v-for="item in 4" :key="item">

          <div v-if="step == item">
            <component 
            :is="passos[item - 1]" 
            @back="backStep"
            @dadosPessoais="dadosPessoais"
            @endereco="endereco"
            @pagamento="pagamento"
            v-bind:nextStepButtonClicked="nextStepButtonClicked"
            />
          </div>

        </div>
      </Container>


      <DetalhesPedido :loading="loading" :step="step" @next="nextStep" />
    </div>
  </div>
</template>

<script>
import ApiService from '../../../services/ApiService'
import DescricaoCheckout from './components/steps/Descricao-checkout.vue'
import Container from "./components/steps/ContainerStepper.vue";
import DadosPessoais from "./components/steps/DadosPessoais.vue";
import Endereco from "./components/steps/Endereco.vue";
import Pagamento from "./components/steps/Pagamento.vue";
import Agradecimento from "./components/steps/Agradecimento.vue";
import DetalhesPedido from "./components/detallhesPedido/DetalhesPedido.vue";
import Error from './components/steps/Error.vue'
export default {
  data() {
    return {
      step: 1,
      loading: false,
      apiService: new ApiService(),
      nextStepButtonClicked: false,
      checkout: {},
      atualStepperOk: false,
      dadosPessoaisObject: undefined,
      enderecoObject: undefined,
      pagamentoObject: undefined,
    };
  },
  components: {
    DescricaoCheckout,
    Container,
    DadosPessoais,
    Endereco,
    Pagamento,
    Agradecimento,
    DetalhesPedido,
    Error
  },
  methods: {
    nextStep() {
      if (this.step <= this.passos.length - 1) {
        if(this.atualStepperOk){
          this.step++;
        }
      }
      this.nextStepButtonClicked = true

      setTimeout(() =>{this.nextStepButtonClicked = false}, 500)
    },
    backStep() {
      this.step--;
    },
    dadosPessoais(dadosPessoais){
      this.dadosPessoaisObject = dadosPessoais
      this.setStateStepper(dadosPessoais)
    },
    endereco(endereco){
      this.enderecoObject = endereco
      this.setStateStepper(endereco)
    },
    pagamento(pagamento){
      this.pagamentoObject = pagamento
      this.checkout = {...this.enderecoObject, ...this.dadosPessoaisObject, ...this.pagamentoObject};
      this.efetuarCadastroCheckout()
    },
    setStateStepper(e){
      this.atualStepperOk = e.ok
      setTimeout(() =>{this.atualStepperOk = false}, 500)
    },
    async efetuarCadastroCheckout(){
      try {
        this.loading = true
        let req = await this.apiService.post('checkout/add', this.checkout)
        if(req.statusCode == 200){
          this.loading = false
          this.setStateStepper(this.checkout)
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toast.error('erro ao realizar checkout')
      }
    }
  },
  computed: {
    passos() {
      return ["DadosPessoais", "Endereco", "Pagamento", "Agradecimento"];
    },
  },
  watch:{
    atualStepperOk(ok){
      if(ok){
        this.nextStep()
      }
    }
  },
  created(){
   // console.log(this.$store.getters.$preUserRegister)
  }
};
</script>
<style scoped>
.checkout {
  background: #f6f6f6;
  width: 100%;
  padding: 0.5rem;
}
.content {
  width: 100%;
}
.container-stepper {
  margin: 1.5rem 0;
}
</style>