var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "detalhes-pedido" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v(" Detalhes do pedido: ")]),
        ]),
        _c("div", { staticClass: "elements" }, [
          _c("div", { staticClass: "descricoes" }, [
            _c("span", { staticClass: "title-descricao" }, [
              _vm._v(" Plano " + _vm._s(_vm.planoEscolhido.title) + " "),
            ]),
            _c("span", { staticClass: "descricao-produto" }, [
              _vm._v(
                " " + _vm._s(_vm.planoEscolhido.periodicidadePagamento) + " "
              ),
            ]),
            _c("span", { staticClass: "empresa" }, [
              _vm._v(" Kristal Connect "),
            ]),
            _c("span", { staticClass: "valor" }, [
              _vm._v(" " + _vm._s(_vm.planoEscolhido.valor) + " "),
            ]),
            _c("span", { staticClass: "tipo-pagamento" }, [
              _vm._v(" Pagamento à vista "),
            ]),
          ]),
        ]),
      ]),
      _vm.step < 4
        ? _c(
            "v-btn",
            {
              staticClass: "btn-prosseguir",
              attrs: { loading: _vm.loading, color: "primary" },
              on: { click: _vm.next },
            },
            [_c("span", [_vm._v(" Prosseguir ")])]
          )
        : _vm._e(),
      _vm.step == 4
        ? _c(
            "div",
            {
              staticClass: "btn-prosseguir principalPagina",
              on: { click: _vm.goToLogin },
            },
            [_c("span", [_vm._v(" Ir para carteira ")])]
          )
        : _vm._e(),
      _c("div", { staticClass: "seguranca" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/shield_icon.svg"),
            alt: "shield",
          },
        }),
        _c("span", [_vm._v(" COMPRA 100% SEGURA ")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }