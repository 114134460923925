var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "btn",
      on: {
        click: function ($event) {
          return _vm.$emit("buttonClicked")
        },
      },
    },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/icons/chevron_left_icon.svg"),
          alt: "Botão de voltar",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }